import { LineChart } from "@mui/x-charts";
import { Items, ProductList, Props } from "../type";
import numberWithCommas from "../utils";
import { useState } from "react";
import Modal from "react-modal";
import SubMain from "../subs";

const domain = "https://restocking.xyz";
const subMainLink = "/SubMain";
function paginate(
  array: ProductList[],
  page_size: number,
  page_number: number
) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export default function ListCategory({
  list,
  category_id: cid,
  count,
}: Props): JSX.Element {
  console.log("-->", list, cid);
  const showData: ProductList[] = [];
  //const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflow = "unset";
  }

  list.forEach((m) => {
    const { items } = m;
    if (items.length > 0) {
      const { vendorItems } = items[0];

      if (vendorItems.length > 0) {
        const { vendorItemPrices } = vendorItems[0];
        if (vendorItemPrices.length > 1) {
          showData.push(m);
        }
      }
    }
  });

  const paginCnt = 4;
  const ulCnt = Math.floor(showData.length / paginCnt);
  const arr = new Array(ulCnt).fill(0);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="modalStyle"
      >
        <SubMain category_id={cid} closeModal={closeModal} />
      </Modal>
      <PagedUl
        list={showData}
        onOpenModal={openModal}
        category_id={cid}
        count={count}
      />
    </>
  );
  // return (
  //   <>

  //     {arr.map((_, index) => {
  //       const arrUl = paginate(showData, paginCnt, index + 1);
  //       console.log(ulCnt, arrUl, paginCnt, index);
  //       return <PagedUl list={arrUl} />;
  //     })}
  //   </>
  // );
}

const PagedUl = ({ list, category_id, count, onOpenModal }: Props) => {
  return (
    <>
      <div>
        <ul className="recentListUl">
          {list.map(({ items, name, image, id, outlink }, index) => {
            if (count && index > count) {
              return <></>;
            }
            if (items.length === 0) {
              return <></>;
            }

            const { vendorItems } = items[0];

            if (vendorItems.length === 0) {
              return <></>;
            }
            const { vendorItemPrices } = vendorItems[0];
            const sorted = vendorItemPrices.sort((a, b) => b.id - a.id);

            const currentPrice = sorted[0].price;

            const barChartData = sorted.reduce<number[]>((acc, cur) => {
              acc.push(cur.price);
              return acc;
            }, []);

            const barChartX = sorted.reduce<number[]>((acc, cur) => {
              const d = new Date(cur.createdAt);
              acc.push(d.getDate());
              return acc;
            }, []);
            const imageLink = image
              ? image.replace(
                  "https://s3.ap-northeast-2.amazonaws.com/www.restocking.xyz",
                  domain
                )
              : "";
            const detailLink = `/detail/2/${id}`;
            return (
              <li key={id} className="productRecentListLi">
                <a href={detailLink} className="boxHref">
                  <div className="imageWrapLi">
                    <img
                      src={imageLink}
                      width="150"
                      height="150"
                      className="imageThum"
                    />
                  </div>

                  <div className="desc">
                    <ul>
                      <li className="descLi">{name}</li>
                      <li className="liPrice">
                        {/* <img
                      src="/img/sale_ic.png"
                      width="30"
                      height="30"
                      className="imageThum"
                    /> */}
                        {numberWithCommas(currentPrice)}
                        {"원"}
                      </li>
                      <li className="liDesc1">가격 변동이 있어요!</li>
                      <li className="liDesc2">
                        자세한 할인 내역을 클릭해서 보셔요.
                      </li>
                      {/* <li>
                      <a
                        href={`https://search.naver.com/search.naver?query=${name}+코스트코`}
                        target="_blank"
                        className="buttonToCoupangWrapTag"
                      >
                        <div className="buttonToDetail">자세히 보기</div>
                      </a>
                    </li> */}
                      <div>
                        {outlink == null ? (
                          <></>
                        ) : (
                          <div className="buttonToCoupangWrap">
                            <a
                              //href={`https://search.naver.com/search.naver?query=${name}+코스트코`}
                              href={outlink}
                              target="_blank"
                              className="buttonToCoupangWrapTag"
                            >
                              <div className="buttonToCoupang">
                                쿠팡 유사 상품
                              </div>
                            </a>
                          </div>
                        )}
                      </div>
                    </ul>
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      {count > 0 ? (
        <div onClick={onOpenModal} className="moreWrap">
          {"더보기 > "}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
