import Header from "../components/header";
import NaverLogin from "./naverLogin";

export default function Login() {
  return (
    <>
      <Header />
      <NaverLogin />
    </>
  );
}
