import displays from "../jsons/re_display.json";
import reProducts from "../jsons/re_product.json";
import numberWithCommas from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons"; // ♥︎
import { useState } from "react";
const domain = "https://restocking.xyz";

type DisplayListType = {
  createdAt: string;
  updatedAt: string;
  id: number;
  title: string;
  isAvailable: boolean;
  description?: string | null;
  pids: string;
};

type ReProductType = {
  name: string;
  createdAt: string;
  updatedAt: string;
  id: number;
  isDelete: boolean;
  description?: string | null;
  options: ReOptionsType[];
  isAvailable: boolean;
};

type ReOptionsType = {
  image: string;
  price: number;
  cases: ReCaseType[];
};

type ReCaseType = {
  sourceId: string | number;
  price: number;
  link: string | null;
  deliveryFee: number;
  provider: string;
};

type DisplayProps = {
  displayName: string;
  displayCreatedAt: string;
  imageUrl: string;
  items: any[];
};

export default function Display() {
  const title = "";
  const createdAt = "";
  const imageUrl = "";
  const [loginState, setLoginState] = useState(false);

  const displayList = displays.sort((a, b) => b.id - a.id);

  const displayListData = displayList.map((d) => {
    const { pids, title } = d;
    const productList: ReProductType[] = [];
    const displays = pids.split(",").map((pid) => {
      const rep = reProducts.find((product) => product.id.toString() === pid);
      if (rep) {
        productList.push(rep);
      }
      return {
        displayName: title,
        displayCreatedAt: createdAt,
        productList,
      };
    });
    return displays;
  });

  const ProviderLogo = (provider: string) => {
    if (provider === "COUPANG") {
      return (
        <img
          src="./img/cpLogo.png"
          width="100"
          height="30"
          className="imgCoupangIcon"
        />
      );
    }
    if (provider === "TMON") {
      return (
        <img
          src="./img/tmLogo.jpg"
          width="100"
          height="30"
          className="imgCoupangIcon"
        />
      );
    }
    if (provider === "GMARKET") {
      return (
        <img
          src="./img/gmLogo.png"
          width="100"
          height="30"
          className="imgCoupangIcon"
        />
      );
    }
    if (provider === "AUCTION") {
      return (
        <img
          src="./img/atLogo.png"
          width="100"
          height="30"
          className="imgCoupangIcon"
        />
      );
    }
    if (provider === "WEMAKEPRICE") {
      return (
        <img
          src="./img/wpLogo.png"
          width="100"
          height="30"
          className="imgCoupangIcon"
        />
      );
    }
  };

  const handleHeartClick = (e: any) => {
    if (loginState === false) {
      if (window.confirm("로그인이 필요한 서비스입니다. 로긴하시겠습니까?")) {
        window.location.href = "/login";
      }
    }
    console.log(e.target);
  };
  console.log(displayListData);
  return (
    <div>
      {displayListData.map((d) => {
        const { displayName, displayCreatedAt, productList } = d[0];

        if (!productList) return null;
        return (
          <div className="overlayContainer">
            <div className="titleWrap">
              <img
                src="./img/new-icon.png"
                width="20"
                height="20"
                className="imgCoupangIcon"
              />
              {displayName}
            </div>

            <div className="itemlistRoot">
              <div className="imageList">
                {productList.map((item) => {
                  const { isAvailable } = item;
                  const option = item.options[0];
                  const cases = option.cases;
                  if (!cases || cases.length === 0) return null;
                  const currentCase = cases[0];
                  console.log({ currentCase });

                  const imageOverlayClass = isAvailable
                    ? "imgProduct"
                    : "imgProduct imageOverlay";
                  return (
                    <>
                      {!isAvailable ? (
                        <div className="overlay">
                          <div className="gfg1">종료</div>
                        </div>
                      ) : null}

                      <a href={currentCase.link ?? ""}>
                        <div></div>
                        <div key={item.id} className="item">
                          <img
                            src={option.image}
                            alt={item.name}
                            className={imageOverlayClass}
                          />
                        </div>

                        <div className="descContainer">
                          <div>
                            {numberWithCommas(currentCase.price) + "원"}
                          </div>
                          {
                            <div className="deliverySection">
                              {currentCase.provider === "COUPANG" &&
                              currentCase.deliveryFee === 0 ? (
                                <img
                                  src="./img/rocket.png"
                                  width="100"
                                  height="30"
                                  className="imgCoupangIcon"
                                />
                              ) : (
                                <>{ProviderLogo(currentCase.provider)}</>
                              )}
                            </div>
                          }
                        </div>
                      </a>
                      {/* <div className="heartBody">
                        <FontAwesomeIcon
                          icon={farHeart}
                          aria-hidden="true"
                          color="red"
                          fontSize={25}
                          onClick={handleHeartClick}
                        />
                      </div> */}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
