import ListCategory from "../components/listCategory";

import products from "../jsons/categoryProducts.json";
import categories from "../jsons/categories.json";
import { useState } from "react";
type SubMainProps = {
  category_id?: number;
  closeModal: () => void;
};
export default function SubMain({
  category_id: cid,
  closeModal,
}: SubMainProps) {
  const sortedProduct = products.sort((a, b) => b.id - a.id);
  const [productLists, setProductLists] = useState(sortedProduct);
  const { title } = categories.filter((m) => m.category_id === cid)[0];
  const plist = products.filter(({ category_id }) => category_id === cid);

  console.log(">>>>>>>>", cid, { products });
  return (
    <>
      <div className="ProductPart">
        <div className="topProducts">
          <div className="modalTitleSet">
            <img src="./img/check-mark.png" width="30" height="30" />
            {title}
            <img
              src="./img/x-icon.png"
              width="25"
              height="25"
              onClick={closeModal}
            />
          </div>
          {/* <div className="setWrap">
            <ListCategory list={plist} category_id={cid} count={0} />
          </div> */}
        </div>
      </div>
    </>
  );
}
