import DetailChart from "../components/chart";
import { Link, useParams } from "react-router-dom";

import products from "../jsons/products.json";
import { useContext } from "react";
import numberWithCommas from "../utils";

const domain = "https://restocking.xyz";

type ChartPriceType = {
  date: string;
  price: number;
};

const handleShare = (id: string, text: string, price: number) => {
  if (navigator.share) {
    navigator
      .share({
        title: "할인정보",
        text: `${text} / ${price}원 `,
        url: `https://restocking.xyz/detail/${id}`,
      })
      .then(() => console.log("공유 성공"))
      .catch((error) => console.log("공유 실패", error));
  }
};
export default function Detail() {
  const { id, productName, vendorId } = useParams();

  const p = useParams();
  const num_id = parseInt(id ? id : "1");
  const options = productName?.split(",");
  let index = 0;
  const optionString = options
    ?.filter((m, index) => index > 0)
    ?.reduce((acc, cur) => {
      acc = acc + cur.replaceAll(" ", "");
      index++;
      return acc;
    }, "");
  const prd = products.filter((m) => m.id === num_id)[0];

  // trim optionType
  const { items, image, name, outlink } = prd;

  const chartData: ChartPriceType[] = [];

  items.map((item) => {
    console.log({ item });
    const originOption1 = item.optionType
      .replaceAll(" ", "")
      .replaceAll(",", "");
    if (vendorId === "1" && originOption1 !== optionString) {
      console.log(
        "optionType is not matched",
        originOption1,
        "<>",
        optionString
      );
      return;
    }
    const vendorItems = item.vendorItems;
    vendorItems.map((vendorItem) => {
      const vendorItemPrices = vendorItem.vendorItemPrices;
      vendorItemPrices.map((v) => {
        const { createdAt, price } = v;
        const t: ChartPriceType = { date: createdAt, price };
        chartData.push(t);
      });
    });
  });

  console.log({ chartData });
  const { vendorItems } = items[0];
  console.log({ vendorItems });
  if (vendorItems.length === 0) {
    return <></>;
  }

  const currentPrice = chartData[0].price;

  const barChartData = chartData.reduce<number[]>((acc, cur) => {
    acc.push(cur.price);
    return acc;
  }, []);

  const barChartX = chartData.reduce<string[]>((acc, cur) => {
    const d = new Date(cur.date);
    acc.push(d.getDate().toString());
    return acc;
  }, []);

  const imageLink = image
    ? image.replace(
        "https://s3.ap-northeast-2.amazonaws.com/www.restocking.xyz",
        domain
      )
    : "";
  return (
    <>
      <div className="detailWrapp">
        <div className="detailNavigation">
          <div className="detailWrappTitle">상세 정보</div>
          <Link to="/">
            <img alt="" src="/img/arrow.png" width="25px" height="25px" />
          </Link>
        </div>
        <div className="detailImage">
          <img
            src={imageLink}
            width="100%"
            height="100%"
            alt="Picture of the author"
          />
        </div>
        <div className="descBox1">
          <div className="vendorTitle">
            {vendorId === "1" ? "쿠팡" : "코스트코"}
            {"상품"}
            {/* <img alt="" src="/img/co-logo.png" width="100px" height="25px" /> */}
          </div>
          <div className="nameTitle">{productName}</div>
          <div>
            {numberWithCommas(currentPrice)}
            {"원"}
          </div>
        </div>
        <div className="descBox2">
          <div>가격 추이 그래프</div>
          <div className="detailContent">
            <DetailChart barChartX={barChartX} barChartData={barChartData} />
          </div>
        </div>
      </div>
      <div className="detailButtonWrap">
        <div className="coupangButtonContainer">
          <a
            //href={`https://search.naver.com/search.naver?query=${name}+코스트코`}
            href={outlink ? outlink : ""}
            target="_blank"
            className="buttonToCoupangWrapTag"
          >
            <div className="buttonToCoupangDetail">쿠팡 상품 사러가기</div>
          </a>
        </div>

        <div
          onClick={() => handleShare(id ? id : "", name, currentPrice)}
          className="shareButton"
        >
          공유하기
        </div>
      </div>
      <div className="titleCoupangPartner">
        "이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를
        제공받습니다."
      </div>
    </>
  );
}
