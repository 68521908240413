import { LineChart } from "@mui/x-charts";
import {
  CategoryTitleType,
  CoupangProductList,
  DateGroupedList,
  TodayTabsType,
} from "../type";
import products from "../jsons/products.json";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import numberWithCommas from "../utils";
import React, { useState } from "react";
import Modal from "react-modal";
import SubMain from "../subs";
import { Typography } from "@mui/material";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

const domain = "https://restocking.xyz";
const subMainLink = "/SubMain";
function paginate(
  array: CoupangProductList[],
  page_size: number,
  page_number: number
) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

type CoupangProductListProps = {
  list: CoupangProductList[];
  onOpenModal?: () => void;
  category_id?: number;
  count?: number;
  title?: string;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

function getDateString(today: Date) {
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
}
export default function ListCoupang({ list }: CoupangProductListProps) {
  const [value, setValue] = React.useState(0);
  const [chipValue, setChipValue] = React.useState("식품");

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChipChange = (event: React.SyntheticEvent, newValue: string) => {
    setChipValue(newValue);
  };

  const latest_date_string = list
    .sort((a, b) => b.id - a.id)[0]
    .createdAt.substring(0, 10);
  const sorted = list
    .filter((item) => item.createdAt.startsWith(latest_date_string))
    .sort((a, b) => b.id - a.id)
    .sort((a, b) => a.rank - b.rank);

  const categoryMap = new Set();
  categoryMap.add("식품");
  categoryMap.add("생활용품");

  const dates: DateGroupedList =
    sorted &&
    sorted.reduce((acc: any, cur) => {
      const { createdAt } = cur;
      const id = createdAt.substring(0, 10);
      (acc[id] = acc[id] || []).push(cur);

      return acc;
    }, {});

  const titles = Object.keys(dates);

  Object.entries(dates).map((date, index) => {
    const [day, data] = date;
    const dataList = Array.isArray(data) ? data : [];

    dataList.map((item) => {
      if (item.keyword === null) return;
      if (!categoryMap.has(item.keyword as string)) {
        categoryMap.add(item.keyword as string); // Use set() method to add items to the map
      }
    });
  });

  return (
    <>
      <div
        style={{
          margin: "5px",
        }}
      >
        <ChipCategory
          list={Array.from(categoryMap) as string[]}
          handleChange={handleChipChange}
        />
      </div>
      {<TabsCoupang list={titles} handleChange={handleTabChange} />}
      {Object.entries(dates).map((date, index) => {
        const [day, data] = date;
        const dataList = Array.isArray(data) ? data : [];

        // get datalist filter where keyword is equal to chipValue
        const filteredList = dataList.filter(
          (item) => item.keyword === chipValue
        );

        return (
          <CustomTabPanel value={value} index={index}>
            <PagedUl list={filteredList} title={day} />
          </CustomTabPanel>
        );
      })}
    </>
  );
}

const ChipCategory = ({ list, handleChange }: CategoryTitleType) => {
  return (
    <Stack direction="row" spacing={1}>
      {list.map((name, index) => (
        <Chip
          label={name}
          variant="outlined"
          onClick={(e) => handleChange(e, name)}
          clickable
          component="div"
        />
      ))}
    </Stack>
  );
};

const TabsCoupang = ({ list, handleChange }: TodayTabsType) => {
  return (
    <Tabs value={list} onChange={handleChange} aria-label="basic tabs example">
      {list.map((date, index) => (
        <Tab label={date} {...a11yProps(index)} />
      ))}
    </Tabs>
  );
};

const PagedUl = ({ list, title }: CoupangProductListProps) => {
  return (
    <>
      <div>
        <ul className="recentListUl">
          {list &&
            list.map(
              (
                {
                  id,
                  categoryName,
                  productImage,
                  productName,
                  productPrice,
                  productUrl,
                  isFreeShipping,
                  isRocket,
                  rank,
                  productId,
                },
                index
              ) => {
                const pid = products.find(
                  (product) => product.ref_id === productId
                )?.id;

                return (
                  <>
                    <li key={id} className="productRecentListLi">
                      {rank === 1 ? (
                        <img
                          src="./img/1th.png"
                          width="30"
                          height="30"
                          className="img1th"
                        />
                      ) : (
                        <></>
                      )}

                      <div className="rank">{rank}</div>
                      <a
                        href={`/detail/1/${pid}/${productName}`}
                        className="boxHref"
                      >
                        <div className="imageWrapLi">
                          <img
                            src={productImage}
                            width="150"
                            height="150"
                            className="imageThum"
                          />
                        </div>

                        <div className="desc">
                          <ul>
                            <li className="descLi">{productName}</li>
                            <li className="liPrice">
                              {/* <img
                      src="/img/sale_ic.png"
                      width="30"
                      height="30"
                      className="imageThum"
                    /> */}
                              {numberWithCommas(productPrice)}
                              {"원"}
                            </li>
                            <li className="liDesc1">
                              클릭해서 가격추이를 보세요!
                            </li>
                            <li className="liDesc2">
                              {isRocket ? "로켓배송" : ""}
                            </li>
                          </ul>
                        </div>
                      </a>
                    </li>
                  </>
                );
              }
            )}
        </ul>
      </div>
    </>
  );
};
