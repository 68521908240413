import { KeyboardEvent, useCallback, useState } from "react";

const Header = () => {
  const [inputText, setInputText] = useState<string>("");

  const handleSearch = useCallback(() => {
    // const filtered = products.filter((m) => m.name.includes(inputText));
    // setProductLists([...filtered]);
  }, [inputText]);

  const handleInputText = (e: React.FormEvent<HTMLInputElement>) => {
    setInputText(e.currentTarget.value);
  };
  const handleKeywordKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="top">
      <a href="/">
        <img
          src="./img/restocklogo.png"
          width="180"
          height="50"
          alt="Picture of the author"
        />
      </a>

      <div className="searchArea">
        <input
          type="text"
          className="searchInput"
          value={inputText}
          onChange={handleInputText}
          onKeyDown={handleKeywordKeyPress}
        />
        <div className="searchButton" onClick={handleSearch}>
          <img src="./img/search.png" width="15px" height="15px" />
        </div>
      </div>
    </div>
  );
};

export default Header;
