import HeartIcon from "../svg/HeartIcon";
import HomeIcon from "../svg/HomeIcon";
import ProfileIcon from "../svg/ProfileIcon";

const BottomNavigator = () => {
  return (
    <div className="bottom-navigator">
      <div className="bottom-navigator__item">
        <div className="bottom-navigator__icon">
          <HomeIcon />
        </div>
        <div className="bottom-navigator__text">Home</div>
      </div>
      <div className="bottom-navigator__item">
        <div className="bottom-navigator__icon">
          <HeartIcon />
        </div>
        <div className="bottom-navigator__text">Likes</div>
      </div>
      <div className="bottom-navigator__item">
        <div className="bottom-navigator__icon">
          <ProfileIcon />
        </div>
        <div className="bottom-navigator__text">Profile</div>
      </div>
    </div>
  );
};

export default BottomNavigator;
