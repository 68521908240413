import React, { useCallback, useEffect, useState } from "react";
import { KeyboardEvent } from "react";
import List from "./components/list";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import gproducts from "./jsons/products.json";
import products from "./jsons/categoryProducts.json";
import categories from "./jsons/categories.json";
import bestCoupang from "./jsons/best_coupang.json";
import ListCategory from "./components/listCategory";
import ListCoupang from "./components/listCoupang";
import Display from "./displays";
import BottomNavigator from "./components/bottomNavigator";
import NaverLogin from "./login/naverLogin";
import Header from "./components/header";
import useDeviceDetection from "./hooks/useDeviceDetection";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

function Home() {
  const sortedProduct = products.sort((a, b) => b.id - a.id);
  const [productLists, setProductLists] = useState(sortedProduct);
  const [value, setValue] = React.useState(0);
  const device = useDeviceDetection();

  //alert(device);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="App">
        <Header />
        <div>
          <Display />
        </div>
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="쿠팡 베스트상품"
              {...a11yProps(0)}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
              }}
            />

            <Tab label="코스트코 상품" {...a11yProps(1)} />
            {/* <Tab label="코스트코 인기상품" {...a11yProps(2)} /> */}
          </Tabs>
          <CustomTabPanel value={value} index={1}>
            <div className="ProductPart">
              {categories.map(({ category_id: cid, title }) => {
                const plist = productLists.filter(
                  ({ category_id }) => category_id === cid
                );

                return (
                  <div className="topProducts">
                    <div className="titleSet">
                      <img src="./img/check-mark.png" width="30" height="30" />
                      {title}
                    </div>
                    <div className="setWrap">
                      <ListCategory list={plist} category_id={cid} count={5} />
                    </div>
                  </div>
                );
              })}
              <div className="WrapProduct">
                <div className="titleSet">쿠팡 코스트코 상품 모음</div>
                <iframe
                  src="https://ads-partners.coupang.com/widgets.html?id=761159&template=carousel&trackingCode=AF7382069&subId=&width=680&height=140&tsource="
                  width="300"
                  height="700"
                ></iframe>
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={0}>
            <div className="topProducts">
              <div>
                <ListCoupang list={bestCoupang} />
              </div>
            </div>
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={2}>
            <div className="topProducts">
              <div>
                <div className="titleSet">코스트코 인기 상품</div>
                <List list={gproducts} count={5} />
              </div>
              <div className="WrapProduct">
                <div className="titleSet">쿠팡 코스트코 상품 모음</div>
                <iframe
                  src="https://ads-partners.coupang.com/widgets.html?id=761159&template=carousel&trackingCode=AF7382069&subId=&width=680&height=140&tsource="
                  width="300"
                  height="700"
                ></iframe>
              </div>
            </div>
          </CustomTabPanel> */}
        </div>
      </div>
      <div className="WrapProduct">
        <iframe
          src="https://ads-partners.coupang.com/widgets.html?id=780875&template=carousel&trackingCode=AF7382069&subId=&width=680&height=140&tsource="
          width="100%"
          height="140"
        ></iframe>
      </div>
      <div className="titleCoupangPartner">
        "이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를
        제공받습니다."
      </div>
      <div className="footer">email : sugyeongh460@gmail.com</div>
      {device !== "Mobile" ? (
        <div className="sticky">
          <BottomNavigator />
        </div>
      ) : null}
    </>
  );
}

export default Home;
