import { LineChart } from "@mui/x-charts";

type Props = {
  barChartX: any;
  barChartData: any;
};

const DetailChart = ({ barChartX, barChartData }: Props) => {
  return (
    <div className="chartDiv">
      <LineChart
        xAxis={[{ data: barChartX }]}
        series={[
          {
            data: barChartData,
          },
        ]}
        width={380}
        height={300}
      />
    </div>
  );
};

export default DetailChart;
