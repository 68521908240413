import { useEffect } from "react";

// 로그인 버튼 컴포넌트
import React from "react";

const NaverLogin = () => {
  const NAVER_CLIENT_ID = "cKPtjHNcHWBj7z9j0Sid"; ///process.env.REACT_APP_NAVER_CLIENT_ID; // 발급받은 클라이언트 아이디
  const REDIRECT_URI = "http://localhost:3001/login/callback"; // Callback URL
  const STATE = "flase";
  const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${REDIRECT_URI}`;

  const NaverLogin = () => {
    window.location.href = NAVER_AUTH_URL;
  };

  //return <button onClick={NaverLogin}>네이버 로그인</button>;
  return (
    <div className="loginContainer">
      <img src="./img/restock_full.png" width="400" height="400" />

      <div className="imgButList">
        <a href={NAVER_AUTH_URL}>
          <img src="./img/naver_login.png" width="300" height="70" />
        </a>
      </div>
    </div>
  );
};

export default NaverLogin;

// const NaverLogin = () => {
//   const clientId = "cKPtjHNcHWBj7z9j0Sid";
//   const callbackUrl = "http://localhost:3001/login/callback";

//   const { naver }: any = window;

//   useEffect(() => {
//     const scriptNaver1 = document.createElement("script");
//     scriptNaver1.src =
//       "https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js";
//     scriptNaver1.type = "text/javascript";
//     scriptNaver1.async = true;
//     document.body.appendChild(scriptNaver1);

//     const scriptNaver2 = document.createElement("script");
//     scriptNaver2.src = "http://code.jquery.com/jquery-1.11.3.min.js";

//     scriptNaver2.type = "text/javascript";
//     scriptNaver2.async = true;
//     document.body.appendChild(scriptNaver2);

//     console.log("scriptNaver1 added");
//     const initializeNaverLogin = () => {
//       if (naver === undefined) {
//         alert(
//           "네이버 아이디로 로그인 모듈을 초기화하는 중입니다. 잠시만 기다려주세요."
//         );
//         return;
//       }
//       const naverLogin = new naver.LoginWithNaverId({
//         clientId,
//         callbackUrl,
//         isPopup: true,
//         loginButton: { color: "green", type: 3, height: "48" },
//       });
//       naverLogin.init();
//     };

//     initializeNaverLogin();
//     return () => {
//       document.body.removeChild(scriptNaver1);
//       document.body.removeChild(scriptNaver2);
//     };
//   }, []);

//   //네이버 로그인 버튼 노출 영역
//   return <div id="naver_id_login"></div>;

//   //네이버 로그인 버튼 노출 영역
// };

//     <script type="text/javascript">
//     var naver_id_login = new naver_id_login("cKPtjHNcHWBj7z9j0Sid",
//     "Hfd5YSG84O"); var state = naver_id_login.getUniqState();
//     naver_id_login.setButton("white", 2,40);
//     naver_id_login.setDomain("localhost:3001");
//     naver_id_login.setState(state); naver_id_login.setPopup();
//     naver_id_login.init_naver_id_login();
//   </script>

/*
네이버 로그인 JavaScript 예제는 2개의 파일로 구성되어 있습니다. (naverlogin.html, callback.html)
1. APIExamNaverLogin.html
<!doctype html>
<html lang="ko">
<head>
  <meta charset="utf-8">
  <title>네이버 로그인</title>
  <script type="text/javascript" src="https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js" charset="utf-8"></script>
  <script type="text/javascript" src="http://code.jquery.com/jquery-1.11.3.min.js"></script>
</head>
<body>
  <!-- 네이버 로그인 버튼 노출 영역 -->
  <div id="naver_id_login"></div>
  <!-- //네이버 로그인 버튼 노출 영역 -->
  <script type="text/javascript">
  	var naver_id_login = new naver_id_login("YOUR_CLIENT_ID", "YOUR_CALLBACK_URL");
  	var state = naver_id_login.getUniqState();
  	naver_id_login.setButton("white", 2,40);
  	naver_id_login.setDomain("YOUR_SERVICE_URL");
  	naver_id_login.setState(state);
  	naver_id_login.setPopup();
  	naver_id_login.init_naver_id_login();
  </script>
</html>


2. callback.html
<!doctype html>
<html lang="ko">
<head>
<script type="text/javascript" src="https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js" charset="utf-8"></script>
<script type="text/javascript" src="http://code.jquery.com/jquery-1.11.3.min.js"></script>
</head>
<body>
<script type="text/javascript">
  var naver_id_login = new naver_id_login("YOUR_CLIENT_ID", "YOUR_CALLBACK_URL");
  // 접근 토큰 값 출력
  alert(naver_id_login.oauthParams.access_token);
  // 네이버 사용자 프로필 조회
  naver_id_login.get_naver_userprofile("naverSignInCallback()");
  // 네이버 사용자 프로필 조회 이후 프로필 정보를 처리할 callback function
  function naverSignInCallback() {
    alert(naver_id_login.getProfileData('email'));
    alert(naver_id_login.getProfileData('nickname'));
    alert(naver_id_login.getProfileData('age'));
  }
</script>
</body>
</html>



*/
